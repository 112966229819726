<template>
  <div>
    <v-row class="my-4 px-2">
      <v-col cols="12" lg="6">
        <v-row v-show="profile.avatar">
          <small v-if="profile.avatar">
            Note: If image doesn't show after upload, please refresh the page.
          </small>
          <v-col cols="12" sm="8" class="d-flex justify-center">
            <div class="avatar-round">
              <v-img
                contain
                :src="profile.avatar"
                width="100%"
                height="100%"
                alt
              />
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="8" class="d-flex justify-center">
            <v-dialog v-model="showAvatarChangeDlg" fullscreen>
              <template v-slot:activator="{ on }">
                <v-btn
                  small
                  color="primary"
                  v-on="on"
                  :disabled="!profile.businessName"
                >
                  {{
                    profile.avatar
                      ? 'Change Business Logo'
                      : 'Add Business Logo'
                  }}
                  <!-- <v-icon>mdi-account-edit</v-icon> -->
                </v-btn>
              </template>

              <v-card>
                <v-card-title class="headline grey lighten-2" primary-title
                  >Change Business Logo</v-card-title
                >
                <v-card-text class="d-flex">
                  <media-uploader
                    @uploadMedia="uploadAvatar"
                    :ratio="1 / 1"
                    imageOnly
                    circle
                  />
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" @click="showAvatarChangeDlg = false"
                    >Back</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  components: {
    MediaUploader: () =>
      import('fod-core/src/components/common/MediaUploader.vue')
  },
  props: {
    profile: {
      type: Object,
      default: null,
      required: true
    }
  },
  data() {
    return {
      showAvatarChangeDlg: false
    }
  },
  methods: {
    uploadAvatar(file, img) {
      this.keepUpdateProfile()
      this.$store.dispatch('uploadMedia', {
        uid: this.uid,
        file: file,
        img: img,
        type: 'avatar'
      })
      this.$store.dispatch('setMessage', {
        title: 'Uploading',
        body: 'This may take few seconds, pleas go back and check the logo. '
      })
    },
    async keepUpdateProfile() {}
  }
}
</script>
<style scoped>
.avatar-round {
  border: gainsboro 5px solid;
  border-radius: 50%;
  overflow: hidden;
  width: 250px;
  height: 250px;
}
.logo-image {
  border: gainsboro 5px solid;
  border-radius: 5px;
  overflow: hidden;
  width: 300px;
  height: 168px;
}
.word-breaking {
  word-break: normal !important;
}
</style>
